<template>
  <div>
    <v-card flat class=" px-3 leave-day">
      <v-layout row wrap justify-space-between align-center v-if="countAsLeave">
        <v-flex xs1>
          <v-avatar size="35" :color="leaveAccrual.leavePolicy.leaveType.color">
            <v-icon size="18" color="white">mdi-umbrella</v-icon>
          </v-avatar>
        </v-flex>
        <v-flex xs4>
          <v-list-item-content>
            <v-list-item-title>{{ $moment(internalValue.date).format("DD ddd MMM YYYY") }}</v-list-item-title>
            <v-list-item-subtitle class="caption">{{ leaveAccrual.leavePolicy.leaveType.name }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-flex>
        <v-flex xs3 v-if="internalValue.duration !== 'HOURLY'">
          <v-select
            v-model="internalValue.period"
            :items="periods"
            v-if="onlyHalfPeriod"
            :disabled="
              !editable || internalValue.duration === 'FULL_DAY' || internalValue.duration === null || isHolidayHalf
            "
            :label="$t('leave.period')"
            :menu-props="{ offsetY: true, overflowY: true, closeOnContentClick: true }"
            color="grey"
            hide-details
            dense
          />
        </v-flex>
        <v-flex xs4 v-if="internalValue.duration !== 'HOURLY'">
          <v-select
            v-if="!value.isExtra"
            v-model="internalValue.duration"
            :items="leaveTypeDurations"
            :disabled="
              !editable ||
                !leaveTypeDurations.length ||
                (leaveTypeDurations.length === 1 && internalValue.duration === 'FULL_DAY') ||
                isHolidayHalf
            "
            :label="$t('leave.duration')"
            :menu-props="{ offsetY: true, overflowY: true, closeOnContentClick: true }"
            color="grey"
            hide-details
            dense
          />
          <div v-else>{{ $t("leave.today_counts_as_a_day_off") }}</div>
        </v-flex>
        <v-flex v-if="internalValue.duration === 'HOURLY'" xs6 class="text-right">
          {{ internalValue.durationValue() }} {{ $t("time_duration.hour") }}
        </v-flex>
      </v-layout>
      <v-layout v-else wrap align-center justify-space-between>
        <v-flex xs1>
          <v-avatar size="35" color="grey lighten-2">
            <v-icon size="18" dark v-if="internalValue.type === 'HOLIDAY'">mdi-bed</v-icon>
            <v-icon size="18" v-else>mdi-home</v-icon>
          </v-avatar>
        </v-flex>
        <v-flex xs5>
          <div class="py-1 grey--text">
            <div class="subtitle-1">{{ $moment(internalValue.date).format("DD MMM YYYY") }}</div>
          </div>
        </v-flex>

        <v-flex xs6>
          <div class="py-1 grey--text caption">
            {{
              $t("leave.request.this_day_does_not_count_as_leave", [
                $t(`shift_types.${internalValue.leaveRequestDayType}`)
              ])
            }}
          </div>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: "LeaveRequestDay",
    props: {
      value: Object,
      leaveTypeDurations: {
        type: Array,
        default: () => []
      },
      editable: Boolean,
      index: Number,
      leaveAccrual: {
        type: Object,
        required: false,
        default: () => {}
      }
    },
    data: vm => ({
      date: null,
      calendarState: false,
      duration: null,
      internalValue: {},
      durations: []
    }),
    watch: {
      value: {
        immediate: true,
        handler(v) {
          if (v) this.internalValue = v;
        }
      },
      "internalValue.period": {
        handler(val) {
          if (!val) {
            this.internalValue.period = this.periods[0].value;
          }
        },
        immediate: true
      },
      "internalValue.duration": {
        handler(v) {
          this.$emit("change:duration");
        },
        immediate: true
      }
    },
    computed: {
      onlyHalfPeriod() {
        const extract = ["FULL_DAY", "HOURLY"];
        return !extract.includes(this.internalValue.duration);
      },
      isHolidayHalf() {
        return this.internalValue.holiday === true && this.internalValue.duration === "HALF_DAY";
      },
      countAsLeave() {
        return this.value.leaveRequestDayType === "ON_LEAVE";
      },
      periods() {
        let intersectPeriods = [
          { text: this.$t("leave.periods.first_half"), value: "FIRST" },
          { text: this.$t("leave.periods.second_half"), value: "SECOND" }
        ];
        let quarterPeriods = [
          { text: this.$t("leave.periods.first_quarter"), value: "FIRST" },
          { text: this.$t("leave.periods.second_quarter"), value: "SECOND" },
          { text: this.$t("leave.periods.third_quarter"), value: "THIRD" },
          { text: this.$t("leave.periods.fourth_quarter"), value: "FOURTH" }
        ];
        if (this.internalValue && this.internalValue.duration === "QUARTER_DAY") {
          intersectPeriods = [...quarterPeriods];
        }
        return intersectPeriods;
      }
    }
  };
</script>

<style scoped>
  .title {
    margin-bottom: 0 !important;
  }

  .v-text-field {
    margin-top: 0;
    padding-top: 0;
  }
  .leave-day {
    box-shadow: 0 2px 5px 0 rgba(34, 41, 47, 0.1) !important;
  }
</style>
